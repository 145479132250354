<script>

export default {
  name: 'MainHeader'
}

</script>

<template>
  <header class="px-3">
    <div
      id="logo"
      class="py-3"
    >
      <img
        src="/assets/images/logo-plantd-h.png"
        height="32"
      >
    </div>
  </header>
</template>