<script>

export default {
  name: 'MainFooter'
}

</script>

<template>
  <footer class="footer">
    <div class="container">
      <span class="text-muted">
        <a
          href="/terms"
          target="_blank"
        >Terms &amp; Conditions</a>
        |
        <a
          href="/eula"
          target="_blank"
        >EULA</a>
        |
        <a
          href="/privacy"
          target="_blank"
        >Privacy Policy</a>
        |
        <a href="mailto:grow@plantd.app">Contact us.</a>
        <p>
          Made by <a href="https://hcweb.io">High Country Web Pty Ltd</a> - ACN: 652 224 943</p>
      </span>
    </div>
  </footer>
</template>

<style scoped>
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f5f5f5;
    font-size: 0.8rem;
    padding-top: 10px;
}

.footer p {
    margin: 0;
}
</style>