import db from "../firebase";
import { query, orderBy, limit, collection, getDocs, doc, getDoc } from "firebase/firestore/lite";

const checkinsRef = collection(db, "checkins");

class PlantDataService {
  async getAll() {
    const plantSnapshot = await getDocs(checkinsRef);
    const plantList = plantSnapshot.docs.map((doc) => doc.data());
    return plantList;
  }

  async getLatest(num) {
    const q = query(checkinsRef, orderBy("created_at", "desc"), limit(num));
    const querySnapshot = await getDocs(q);
    const plantList = querySnapshot.docs.map((doc) => doc.data());
    return plantList;
  }

  async getPlant(id) {
    const docRef = doc(db, "plants", id);
    const docSnapshot = await getDoc(docRef);
    return docSnapshot.data();
  }

}
export default new PlantDataService();
