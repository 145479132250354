<script>
import PlantDataService from '@/services/PlantDataService';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default {
    name: "LatestCheckins",
    data() {        
        return {
            checkins: []
        };
    },
    mounted() {
        const numCheckins = 12;
        const latestCheckins = PlantDataService.getLatest(Math.round(numCheckins*1.4));
        const storage = getStorage();
        let count = 0;

        latestCheckins.then((docs) => {
            docs.forEach(doc => {
                // check if the plant is private
                PlantDataService.getPlant(doc.plant_id ).then((plant) => {
                    if( plant.private === false && count < numCheckins)
                    {
                        count++;
                        const pathReference = ref(storage, `checkin_images/${doc.image}`);
                        getDownloadURL(pathReference)
                        .then((url) => {
                            this.checkins.push(url);
                        })
                        return true;
                     }
                });
            });
        })
    }
}
</script>

<template>
  <div class="grid">
    <img
      v-for="checkin in checkins"
      :key="checkin"
      :src="checkin"
    >
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* grid */
.checkins .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4px;    
}

@media screen and (min-width: 720px) {
    .checkins .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.checkins .grid img {
    width: 100%;
}
</style>