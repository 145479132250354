import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBs0FLIPdtrbc91RUt0zzGSBw3VMlgGrww",
  authDomain: "plantd-app-prod.firebaseapp.com",
  databaseURL: "https://plantd-app-prod.firebaseio.com",
  projectId: "plantd-app-prod",
  storageBucket: "plantd-app-prod.appspot.com",
  messagingSenderId: "55227072989",
  appId: "1:55227072989:web:7721222e8f79c6e6db7634",
  measurementId: "G-F2BV2S28YN",
};
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const db = getFirestore(app);

export default db;
