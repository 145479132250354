<script>

export default {
  name: 'AccordionElement'
}

</script>

<template>
  <div
    id="accordionExample"
    class="accordion mb-3"
  >
    <div class="accordion-item">
      <h2
        id="headingOne"
        class="accordion-header"
      >
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="false"
          aria-controls="collapseOne"
        >
          About plantd
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <p>
            Thanks for checking out our app! This is a very early stage release and has
            limited functionality so keep that in mind when using it.
          </p>
          <p>Plantd is a tool for tracking the growth of plants. As hobby farmers, we find it tricky to share the progress of our plants and get help from others. We also find it hard to find high quality, relevant photos of what we are growing.</p>
          <p>
            We also want to share this data with people far smarter than us, to make it easier for them to see what&apos;s happening in the plant world.
          </p>
          <p>
            We really need a hand with building this app. Please jump on and let us know what <a href="mailto:grow@plantd.app">you think.</a>
          </p>
          <p>We'll see you in the garden! -Mags &amp; Ro</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Accordion */
.accordion-button:focus {
    border-color: #c83d8f;
    box-shadow: 0 0 0 .25rem rgba(200, 61, 143, .25);
}

.accordion-button:not(.collapsed) {
    color: #c83d8f;
    background-color: #f9eaf3;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c83d8f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(180deg);
}
</style>