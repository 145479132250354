<script>
import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<template>
  <HomePage />
</template>

<style>
html {
    position: relative;
    min-height: 100%;
}

body {
    background: linear-gradient(325deg, rgba(200, 61, 143, 0.1) 0%, rgb(255, 255, 255) 100%);
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
