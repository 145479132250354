<script>

import LatestCheckins from "./LatestCheckins.vue";
import MainFooter from "./Footer.vue";
import AccordionElement from "./Accordion.vue";
import MainHeader from "./Header.vue";
import PlantdGoals from "./Goals.vue";

export default {
  name: 'HomePage',
  components: {
    LatestCheckins,
    MainFooter,
    AccordionElement,
    MainHeader,
    PlantdGoals
}
}

</script>

<template>
  <main>
    <div class="container px-3">
      <div class="row">
        <MainHeader />
      </div>
    </div>

    <div class="container px-3">
      <div class="row mt-5">
        <div class="col-lg my-5">
          <h2>Plant it. Grow it. Share it.</h2>
          <div>
            <p>Current Status: Feb 2024. Apps removed from stores. Thanks to everyone who had a look. I'm working on a simpler web version to be released in the first half of 2024.</p>
            <AccordionElement />
          </div>
          <div class="new-features mt-5 alert-light alert">
            <h3>Features</h3>
            <ul>
              <div class="row">
                <div class="col-lg">
                  <li>
                    <strong>Track</strong> plants that you're growing. Take a photo, add a note. See how
                    your plants grow
                    over time.
                  </li>
                  <li>Create a <strong>timelapse</strong> of your plant from the photos you've taken.</li>
                  <li>
                    Follow your friends and family's gardens and <strong>grow</strong> them together.
                  </li>
                  <li>
                    <strong>Help</strong> the community and add plants to the database. You'll earn
                    badges and help build
                    a global plant list!
                  </li>
                  <li><strong>Share</strong> tips with others and help each other grow together.</li>
                  <li>
                    <span class="soon">(Coming soon)</span> Set reminders to water or check on your
                    plant.
                  </li>
                  <li>
                    <span class="soon">(Coming soon)</span> Updated timeline view of your plant to see
                    your photos and notes over time.
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="col-md mt-2 mb-5 text-center">
          <img
            class="screenshot"
            src="/assets/images/plantd_on_phone.jpg"
          >
        </div>
      </div>
    </div>

    <div class="goals">
      <PlantdGoals />
    </div>

    <div class="container px-3">
      <div class="checkins my-5">
        <h2>Latest checkins</h2>
        <LatestCheckins />
      </div>
    </div>
  </main>
  <MainFooter />
</template>

<style>
main {
    padding-bottom: 100px;
}

h2,
h3 {
    font-family: 'Manrope', sans-serif;
    color: #18191F;
}

h2 {
    font-size: 4rem;
}

h3 {
    font-size: 1.3rem;
}

em {
  font-weight: bold;
  color: #c83d8f;
}

a {
    color: #cc96b6;
}

a:hover,
a:visited {
    color: #c83d8f
}

.badges a {
    text-decoration: none;
}

ul li {
  margin: 0.5em 0;
}

.soon {
    color: #c83d8f;
}

.screenshot {
    max-width: 500px;
    width: 100%;
}

    .goals {
        background-color: #fff;
    }
</style>
