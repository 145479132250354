<script>

export default {
  name: 'PlantdGoals'
}
</script>

<template>
  <div class="container px-3">
    <div class="row align-items-center">
      <div class="col-md my-5">
        <h2>Goals</h2>
        <ul>
          <li>We&apos;re creating a citizen built, global, realtime, <em>visual</em> database of plants.</li>
          <li>Allow growers to <em>see changes</em> in plants over time and the effect of local weather events.</li>
          <li>Provide researchers with a database of visual, geolocated plant data over time to track <em>climate impacts.</em></li>
        </ul>
      </div>
      <div class="col-md mt-2 mb-5 text-center">
        <img
          class="globe"
          src="/assets/images/LowPolyPlanet.png"
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
    .goals ul {
        font-size: 1.2rem;
        margin-top: 1em;
    }

    @media screen and (min-width: 480px) {
        .goals ul {
            font-size: 1.5rem;
            margin-left: 2em;
        }
    }
    .globe {
        max-width: 320px;
    }
</style>